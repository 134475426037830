//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { PageWithLinksTypes } from './z_page-types'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import PageWithLinks from '../components/templates/pageWithLinks'
import PageBackground from '../components/free/pageBackground'

const IntensiveRespite: FunctionComponent<PageWithLinksTypes> = ({ data }) => {
  return (
    <FontSizeProvider>
        <Helmet htmlAttributes={{lang: 'en'}}>
          <meta charSet="utf-8" />
          <title>OOA - Intensive Respite</title>
          <link rel="canonical" href="https://ooa.csi.edu/intensive-respite/" />
          <meta
            name="description"
            content="Respite Services provide relief for live-in, 24-hour, family caregiver from the continual demand and stress of providing supervision and/or personal care to individuals with physical or cognitive impairments."
          />
        </Helmet>

      <Header />

      <Layout>
        <PageWithLinks
          html={data.markdownRemark.html}
          frontmatter={data.markdownRemark.frontmatter}
        />
      </Layout>

      <Footer />
      <PageBackground />
    </FontSizeProvider>
  )
}

export default IntensiveRespite

export const intensiveRespite = graphql`
  query intensiveRespite {
    markdownRemark(
      frontmatter: { template_key: { eq: "intensive-respite-page" } }
    ) {
      html
      frontmatter {
        title
        links {
          link
          link_name
        }
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        team_pictures {
          name
          title
          img {
            childImageSharp {
              fluid(maxWidth: 1700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
